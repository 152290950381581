import {Application, Container, Graphics, Text, utils} from "pixi.js";

import { gsap } from "gsap";

import { CRTFilter } from '@pixi/filter-crt';
import {GlowFilter} from '@pixi/filter-glow';
import { GlitchFilter } from '@pixi/filter-glitch';
import FontLoader from "../util/FontLoader";


class PixiApplication {

    //@ts-ignore
    private _application: Application;
    //@ts-ignore
    private _previousWidth: number;
    //@ts-ignore
    private _previousHeight: number;
    //@ts-ignore
    private _crtFilter: CRTFilter;

    //@ts-ignore
    private _secondaryCrtFilter: CRTFilter;

    //@ts-ignore
    private _glitchFilter: GlitchFilter

    private _stringTextToShow: string[];
    private _errorStringsToShot: string[];

    //@ts-ignore
    private _mainText: Text;
    //@ts-ignore
    private _errorText: Text;

    private _background: Graphics

    private _introTimeout: any;
    private _introTimeoutDelay: number;

    constructor() {
        utils.skipHello();
        this._introTimeoutDelay = 1500.0;
        this._stringTextToShow = [
            'Santiago Traverso'
        ];
        this._errorStringsToShot = [
        'Photographer'
        ];
        this._crtFilter = this._prepareCrtFilter();
        this._secondaryCrtFilter = this._prepareSecondaryCrtFilter();
        // this._glitchFilter = this._prepareGlitchFilter();
        this._previousWidth = 0.0;
        this._previousHeight = 0.0;
        this._application = new Application({
            autoDensity: true,
            resolution: devicePixelRatio,
            backgroundColor: 0x0d0d0d
        });
        this._background = new Graphics();
        this._background.beginFill(0x0d0d0d);
        this._background.drawRect(0,0, window.innerWidth, window.innerHeight);
        this._background.endFill();
        this.addChild(this._background);
        document.body.appendChild(this._application.view);
        window.addEventListener('resize', this._onResize.bind(this));
        this._application.stage.filters = [this._crtFilter, this._secondaryCrtFilter];
        this._onResize();
        this._enterFrame();
        const fontLoader = new FontLoader();
        fontLoader.loadFonts(['Didot'], () => {
            this._mainText = new Text(this._stringTextToShow[0], {
                fontFamily: 'Didot',
                fontSize: window.innerWidth > 400  && window.innerHeight > 450? 80 : 30,
                align: "center",
                fill: 0xFFFFFF
                , // 0xFF0000, // ,
                letterSpacing: 3,
                whiteSpace: "normal",
                fontWeight: 'bold',
                wordWrap: false
            });
            this._mainText.pivot.y = this._mainText.height * 0.5;
            this._mainText.pivot.x = this._mainText.width * 0.5;
            this._mainText.x =  (window.innerWidth * 0.5);
            this._mainText.y =  (window.innerHeight * 0.5);
            this._mainText.scale.x = 0.1;
            this._mainText.scale.y = 0.1;
            this.addChild(this._mainText);
            this._mainText.filters=[new GlowFilter({
                color: 0xFFFFFF,
                innerStrength: 0.0,
                outerStrength: 1.5,
            })];
            gsap.to(this._mainText.scale, {x: 1.0, y:1.0, duration: 3.0});
            this._errorText = new Text(this._errorStringsToShot[0], {
                fontFamily: 'Didot',
                fontSize: window.innerWidth > 400 && window.innerHeight > 450 ? 50 : 30,
                align: "center",
                fill: 0xFF0000, // 0xFF0000, // ,
                letterSpacing: 3,
                whiteSpace: "normal",
                fontWeight: 'bold',
                wordWrap: false
            });
            this._errorText.pivot.y = this._errorText.height * 0.5;
            this._errorText.pivot.x = this._errorText.width * 0.5;
            this._errorText.x =  (window.innerWidth * 0.5);
            this._errorText.y = window.innerWidth > 400 && window.innerHeight > 450 ?
                (window.innerHeight * 0.5) + 100 : (window.innerHeight * 0.5) + 50;
            this._errorText.scale.x = 0.9;
            this._errorText.scale.y = 0.9;
            this.addChild(this._errorText);
            this._errorText.filters=[new GlowFilter({
                color: 0xFF0000,
                innerStrength: 0.0,
                outerStrength: 1.0,
            })];
        });
    }


    private _onResize(): void {
        this._reSizeRenderer();
        // Removed because the calculation is wrong but kept for future reference
        this._rePositionChildren();
        this._previousHeight = window.innerHeight;
        this._previousWidth = window.innerWidth;

    }

    private _enterFrame(): void {
        if (this._application) {
            requestAnimationFrame(this._enterFrame.bind(this));
            const stage = this._application.stage;
            this._application.renderer.render(stage);
            this._crtFilter.time += 0.03;
            this._crtFilter.seed += 0.00001;
            this._secondaryCrtFilter.time += 50.0;
            this._secondaryCrtFilter.seed += 0.00001;
        }
    }

    public getApplication(): Application {
        return this._application;
    }

    private _reSizeRenderer(): void {
        const renderer = this._application.renderer;
        renderer.resize(window.innerWidth, window.innerHeight);
    }

    private _rePositionChildren(): void {
        const renderer = this._application.renderer;
        const view = this._application.view;
        const stage = this._application.stage;
        const children = stage.children;
        children.forEach(child => {
            if(!this._isGraphic(child)) {
                child.position.set(view.width * 0.5, view.height * 0.5);
            }
        });
        if(this._errorText) {
            this._errorText.x =  (window.innerWidth * 0.5);
            this._errorText.y = window.innerWidth > 400 && window.innerHeight > 450 ?
                (window.innerHeight * 0.5) + 100 : (window.innerHeight * 0.5) + 50;
        }
        if(this._mainText) {
            this._mainText.x =  (window.innerWidth * 0.5);
            this._mainText.y =  (window.innerHeight * 0.5);
        }
        if(this._background) {
            this._background.clear();
            this._background.beginFill(0x0d0d0d);
            this._background.drawRect(0,0, window.innerWidth, window.innerHeight);
            this._background.endFill();
        }
    }

    private _calculateDeltaScaleX(): number {
        return window.innerWidth - this._previousWidth;
    }

    private _calculateDeltaScaleY(): number {
        return window.innerHeight - this._previousHeight;
    }

    public addChild(child: Container): void {
        const stage = this._application.stage;
        stage.addChild(child);
    }

    public removeChild(child: Container): void {
        const stage = this._application.stage;
        stage.removeChild(child);
    }

    private _prepareCrtFilter(): CRTFilter {
        return new CRTFilter({
            curvature: 1.0,
            lineWidth: 500.0,
            lineContrast: 0.1,
            noise: 0.1,
            noiseSize: 1.0,
            vignetting: 0.1,
            vignettingAlpha: 1.0,
            vignettingBlur: 0.3,
            seed: 0,
            time: 0.0,
        });
    }

    private _prepareSecondaryCrtFilter(): CRTFilter {
        return new CRTFilter({
            curvature: 10.0,
            lineWidth: 0.5,
            lineContrast: 0.1,
            noise: 0.0,
            noiseSize: 0.0,
            vignetting: 0.0,
            vignettingAlpha: 0.0,
            vignettingBlur: 0.0,
            seed: 0,
            time: 0.0,
        });
    }

    private _isGraphic(object: any): object is Graphics {
        return 'beginFill' in object;
    }

    public destroy(): void {
        this._application.destroy(true);
    }
}

export { PixiApplication };
