import React from 'react';
import {Stack, Center, Flex} from '@mantine/core';

import './App.css';
import linkedIn from './bitmaps/linkedinl.png';
import twitter from './bitmaps/wheel3.png';
import instagram from './bitmaps/instagram.png';

import {PixiComponent} from "./components/PixiComponent";
import {isMobile} from 'react-device-detect';


function App() {
  return (
    <div className="App " >

        <Flex
                mih={0}
                bg="rgba(0, 0, 0, 0)"
                gap='xs'
                justify='center'
                align="initial"
                direction="row"
                wrap="wrap">

            <div      className={'bottom-1/2'}>
                <img src = {twitter} alt ='twitter' width={250} height={250}/>
            </div>
        </Flex>
        <PixiComponent/>
    </div>
  );
}

export default App;
